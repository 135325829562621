import './Pricing.css';
import React from 'react';
import { Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import Section from 'components/Section';
import Divider from 'components/Divider';
import { useWindowSize } from 'hooks';
import { reflow } from 'utils/transition';
import { media } from 'utils/style';
import Heading from 'components/Heading';
import Text from 'components/Text';
import PricingCard from '../PricingCard';

import { Button } from '@material-ui/core';

const ProjectSummary = ({
  id,
  visible,
  sectionRef,
  index,
  title,
  description,
  buttonText,
  buttonLink,
  ...rest
}) => {
  const { width } = useWindowSize();
  const titleId = `${id}-title`;
  const isMobile = width <= media.tablet;
  const indexText = index < 10 ? `0${index}` : index;

  const renderDetails = status => (
    <div className="project-summary__details">
      <div aria-hidden className="project-summary__index">
        <Divider
          notchWidth="64px"
          notchHeight="8px"
          collapsed={status !== 'entered'}
          collapseDelay={1000}
        />
        <span
          className={classNames(
            'project-summary__index-number',
            `project-summary__index-number--${status}`
          )}
        >
          {indexText}
        </span>
      </div>
      <Heading
        level={3}
        as="h2"
        className={classNames(
          'project-summary__title',
          `project-summary__title--${status}`
        )}
        id={titleId}
      >
        Protocol Basics
      </Heading>
      <Text
        as="div"
        className={classNames(
          'project-summary__description',
          `project-summary__description--${status}`
        )}
      >
        <PricingCard
          title="basic"
        >
          <div className="card-container">
            <h1
              style={{
                fontFamily: 'Volte',
                fontWeight: '600',
                marginTop: '1rem',
              }}
            >
              BONDING
            </h1>
            <h2
              style={{
                fontFamily: 'Volte',
                fontWeight: '600',
                fontSize: '24px',
                marginTop: '1rem',
              }}
            >
              Supply
            </h2>
            <span className="card-metadata">
              New PALM is minted through bonding and Liquidity Pair (LP) Fees
            </span>
            <Button
              href="https://palmdao.gitbook.io/palmdao/"
              className="card-button"
              variant="contained"
            >
              Learn more
            </Button>
          </div>
        </PricingCard>
        <PricingCard
          title="basic"
        >
          <div className="card-container">
            <h1
              style={{
                fontFamily: 'Volte',
                fontWeight: '600',
                marginTop: '1rem',
              }}
            >"RISK FREE" VALUE
            </h1>
            <h2
              style={{
                fontFamily: 'Volte',
                fontWeight: '600',
                fontSize: '24px',
                marginTop: '1rem',
              }}
            >
              Intrinsic Value
            </h2>
            <span className="card-metadata">
              Treasury inflow is used to regulate staking APY and increase PALM's intrinsic value
            </span>
            <Button
              href="https://palmdao.gitbook.io/palmdao/"
              className="card-button"
              variant="contained"
            >
              Learn more
            </Button>
          </div>
        </PricingCard>
        <PricingCard
          title="basic"
        >
          <div className="card-container">
            <h1
              style={{
                fontFamily: 'Volte',
                fontWeight: '600',
                marginTop: '1rem',
              }}
            >
              REWARDS
            </h1>
            <h2
              style={{
                fontFamily: 'Volte',
                fontWeight: '600',
                fontSize: '24px',
                marginTop: '1rem',
              }}
            >
              Token Holder Rewards
            </h2>
            <span className="card-metadata">
              wPALM holders are rewarded with compunded returns, increasing their wPALM balance
            </span>
            <Button
              href="https://palmdao.gitbook.io/palmdao/"
              className="card-button"
              variant="contained"
            >
              Learn more
            </Button>
          </div>
        </PricingCard>
      </Text>
      <div
        className={classNames(
          'project-summary__button',
          `project-summary__button--${status}`
        )}
      ></div>
    </div>
  );

  return (
    <Section
      className={classNames('project-summary', {
        'project-summary--first': index === '01',
        'project-summary-mobile': isMobile,
      })}
      as="section"
      aria-labelledby={titleId}
      ref={sectionRef}
      id={id}
      tabIndex={-1}
      {...rest}
    >
      <div className="project-summary__content">
        <Transition in={visible} timeout={0} onEnter={reflow}>
          {status => <Fragment>{<Fragment>{renderDetails(status)}</Fragment>}</Fragment>}
        </Transition>
      </div>
    </Section>
  );
};

export default ProjectSummary;
