import './index.css';
import { ReactComponent as HeliumIcon } from 'assets/helium.svg';
import { ReactComponent as DiscordBubble } from 'assets/icons/discord-bubble.svg';
import { ReactComponent as Twitter } from 'assets/icons/twitter.svg';
import { ReactComponent as Github } from 'assets/icons/github.svg';
import { ReactComponent as Gitbook } from 'assets/icons/gitbook.svg';

import { ReactComponent as PalmTreeLogo } from 'assets/palm-tree-logo.svg';

const Footer = ({ isDark }) => (
  <footer className="footer">
    <div className="left-container">
      <div className="left-content">
        <PalmTreeLogo></PalmTreeLogo>
      </div>
      <div style={{'margin-top': '1rem'}} className="left-content">
        <h1>Helium's Reserve Currency</h1>
        <HeliumIcon className="helium-icon"></HeliumIcon>
      </div>
      <div className="left-content">
        <a href="https://discord.gg/DMQ5sAwx3X"><DiscordBubble className="social-icon"></DiscordBubble></a>
        <a href="https://twitter.com/palmtreedao?s=20"><Twitter className="social-icon"></Twitter></a>
        <a href="https://github.com/PalmDAO"><Github className="social-icon"></Github></a>
        <a href="https://palmdao.gitbook.io/palmdao/"><Gitbook className="social-icon"></Gitbook></a>

      </div>
      <span>Ⓒ 2021 - PalmTree all rights reserved</span>
    </div>
    <div className="middle-container">
      <h1 className="footer-h1">Products</h1>
      <a href="#bond"><h2 className="footer-h2">Bond</h2></a>
      <a href="#stake"><h2 className="footer-h2">Staking</h2></a>
    </div>
    <div className="right-container">
    <h1 className="footer-h1">Join the community</h1>
      <a href="https://discord.gg/DMQ5sAwx3X"><h2 className="footer-h2">Join Discord</h2> </a>
    </div>
  </footer>
);

export default Footer;
