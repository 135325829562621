import { Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import DecoderText from 'components/DecoderText';
import Section from 'components/Section';
import { reflow } from 'utils/transition';
import Heading from 'components/Heading';
import Text from 'components/Text';
import './Profile.css';

const ProfileText = ({ status, titleId }) => (
  <Fragment>
    <Heading
      className={classNames('profile__title', `profile__title--${status}`)}
      level={3}
      id={titleId}
    >
      <DecoderText text="About us" start={status !== 'exited'} delay={500} />
    </Heading>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      Everything you know and love about community-owned high APY protocols except one important difference. A portion of Helium’s treasury will dedicated to an Accelerator program that accepts up-and-coming DeFi startups, NFTs and more.    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      PALM is the first decentralized autonomous organization (DAO) hosted on the Avalanche Network with a mutual fund like basket of assets primarily focused on hotspot/internet of things (IoT) based crypto projects. Each PALM token is backed by the treasury assets (e.g., HGN, PALM-AVAX LP Tokens etc etc), giving it an intrinsic value. PALM  tokens backed by the treasury are controlled by a minting/burning algorithm that guarantees a price floor (based on treasury holdings) for all PALM.
    </Text>
    <Text
      className={classNames('profile__description', `profile__description--${status}`)}
      size="l"
    >
      Feeling lost in the crypto world? We are here to help you get started. Send us a
      message, we would love to help!
    </Text>
  </Fragment>
);

const Profile = ({ id, visible, sectionRef }) => {
  const titleId = `${id}-title`;

  return (
    <Section
      className="profile"
      as="section"
      id={id}
      ref={sectionRef}
      aria-labelledby={titleId}
      tabIndex={-1}
    >
      <Transition in={visible} timeout={0} onEnter={reflow}>
        {status => (
          <div className="profile__content">
            <div className="profile__column">
              <ProfileText status={status} titleId={titleId} />
              <Button
                variant="contained"
                className="send-message-button"
              >
                Send message
              </Button>
            </div>
          </div>
        )}
      </Transition>
    </Section>
  );
};

export default Profile;
