import React from 'react';
import './PricingCard.css';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';

const PricingCard = props => {
  return [
    <Card
      elevation={8}
      className={classNames(
        `pricing-card ${props.title}-tier ${props.disabled ? 'disabled-card' : ''}`
      )}
    >
      {props.children}
    </Card>,
  ];
};

export default PricingCard;
