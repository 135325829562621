import { Suspense, lazy, useEffect, useState, Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { useInterval, usePrevious, useWindowSize } from 'hooks';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import { media } from 'utils/style';
import { ReactComponent as ArrowDown } from 'assets/arrow-down.svg';
import { ReactComponent as HeliumIcon } from 'assets/helium.svg';
import Heading from 'components/Heading';
import Section from 'components/Section';
import { useTheme } from 'components/ThemeProvider';
import VisuallyHidden from 'components/VisuallyHidden';
import './Intro.css';

const DisplacementSphere = lazy(() =>
  import('pages/Home/DisplacementSphere/DisplacementSphere')
);

function Intro({ id, sectionRef, disciplines, scrollIndicatorHidden, ...rest }) {
  const theme = useTheme();
  const [disciplineIndex, setDisciplineIndex] = useState(0);
  const windowSize = useWindowSize();
  const prevTheme = usePrevious(theme);
  const introLabel = [disciplines.slice(0, -1).join(', '), disciplines.slice(-1)[0]].join(
    ', and '
  );
  const titleId = `${id}-title`;
  const isMobile = windowSize.width <= media.mobile || windowSize.height <= 696;

  useInterval(
    () => {
      const index = (disciplineIndex + 1) % disciplines.length;
      setDisciplineIndex(index);
    },
    5000,
    theme.themeId
  );

  useEffect(() => {
    if (prevTheme && prevTheme.themeId !== theme.themeId) {
      setDisciplineIndex(0);
    }
  }, [theme.themeId, prevTheme]);

  return (
    <Section
      className="intro"
      as="section"
      ref={sectionRef}
      id={id}
      aria-labelledby={titleId}
      tabIndex={-1}
      {...rest}
    >
      <Transition
        key={theme.themeId}
        appear={!prerender}
        in={!prerender}
        timeout={3000}
        onEnter={reflow}
      >
        {status => (
          <Fragment>
            {!prerender && (
              <Suspense fallback={null}>
                <DisplacementSphere />
              </Suspense>
            )}
            <header className="intro__text">
              <Heading level={0} className="intro__title">
                <VisuallyHidden className="intro__title-label">{`Treasury-backed ${introLabel}`}</VisuallyHidden>
                <span
                  aria-hidden
                  className={classNames('intro__title-row', {
                    'intro__title-row--hidden': prerender,
                  })}
                >
                  <span
                    style={{
                      fontFamily: 'Volte',
                      fontWeight: '600',
                      fontSize: isMobile ? '36px' : '82px',
                    }}
                  >
                    A mutual fund like basket of assets backed by the peoples network.
                  </span>
                </span>
              </Heading>
            </header>
            {windowSize.width > media.mobile && (
              <Fragment>
              <div
                className={classNames(
                  'intro__scroll-indicator',
                  `intro__scroll-indicator--${status}`,
                  { 'intro__scroll-indicator--hidden': scrollIndicatorHidden }
                )}
              >
                <div class="intro-stat-container">
                  <h3 class="stat-primary">
                    Total Staked
                  </h3>
                  <h2 class="stat-secondary">
                    0
                  </h2>
                </div>
                <div>
                <h3 class="stat-primary">
                    Treasury Balance
                  </h3>
                  <h2 class="stat-secondary">
                    $0
                  </h2>
                </div>
                <div >
                <h3 class="stat-primary">
                    Current APY
                  </h3>
                  <h2 class="stat-secondary">
                    TBA
                  </h2>
                </div>
              </div>

              <div
              className={classNames(
                'intro__helium-indicator',
                `intro__helium-indicator--${status}`,
                { 'intro__helium-indicator--hidden': scrollIndicatorHidden }
              )}
            >
              <HeliumIcon aria-hidden />
            </div>
            </Fragment>
            )}
            {windowSize.width <= media.mobile && (
              <div style={{ display: 'flex', width: '100%'}}>
              <div
                className={classNames(
                  'intro__mobile-scroll-indicator',
                  `intro__mobile-scroll-indicator--${status}`,
                  { 'intro__mobile-scroll-indicator--hidden': scrollIndicatorHidden }
                )}
              >
                <ArrowDown aria-hidden />
              </div>
                 <div
                className={classNames(
                  'intro__mobile-scroll-indicator-stats',
                  `intro__mobile-scroll-indicator--${status}`,
                  { 'intro__mobile-scroll-indicator--hidden': scrollIndicatorHidden }
                )}
              >
                                <div class="intro-stat-container">
                  <h3 class="stat-primary">
                    Total Staked
                  </h3>
                  <h2 class="stat-secondary">
                    0
                  </h2>
                </div>
                <div>
                <h3 class="stat-primary">
                    Treasury Balance
                  </h3>
                  <h2 class="stat-secondary">
                    $0
                  </h2>
                </div>
                <div >
                <h3 class="stat-primary">
                    Current APY
                  </h3>
                  <h2 class="stat-secondary">
                    TBA
                  </h2>
                </div>
              </div>
            </div>
            )}
          </Fragment>
        )}
      </Transition>
    </Section>
  );
}

export default Intro;
